import React from "react";
import styled from "styled-components";
import Vertical from "../vertical";
import Minheight from "../minheight";
import ImageRatio from "../image_ratio";

const Flex = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Left = styled.div`
  margin-left: 20px;
  width: calc(100% - 20px);
  margin-bottom: 50px;
`;

const Right = styled.div`
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
`;

const Col = styled.div`
  grid-area: 1 / 2 / 2 / 6;
`

const Module4Mobile = ({data}) => {
  return (
    <Minheight mobile>
      <Flex>
        <Left><ImageRatio image={data.left_image.asset.url} ratio="710/520" /></Left>
        <Right>
          <Col>
            <Vertical>
              <ImageRatio image={data.right_image.asset.url} ratio="440/465" />
            </Vertical>
          </Col>
        </Right>
      </Flex>
    </Minheight>
  );
};

export default Module4Mobile;

import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Product from '../components/product'
import ProductMobile from '../components/product_mobile'
import Seo from '../components/seo'
import useWindowSize from '../hooks/useGatsbyWindowSize'

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      title
      blurb
      modules {
        ... on SanityModule1 {
          _key
          _type
          title
          image {
            asset {
              url
            }
          }
        }
        ... on SanityModule10 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
          title
        }
        ... on SanityModule11 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
          title
        }
        ... on SanityModule9 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
        }
        ... on SanityModule8 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
        }
        ... on SanityModule7 {
          _key
          _type
          title
          image {
            asset {
              url
            }
          }
        }
        ... on SanityModule6 {
          _key
          _type
          title
          image {
            asset {
              url
            }
          }
        }
        ... on SanityModule5 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
          title
        }
        ... on SanityModule4 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
          title
        }
        ... on SanityModule3 {
          _key
          _type
          image {
            asset {
              url
            }
          }
          title
        }
        ... on SanityModule2 {
          _key
          _type
          body
          price
          title
          link_text
          image {
            asset {
              url
            }
          }
          pdf {
            asset {
              url
            }
          }
        }
        ... on SanityModule13 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
          title
        }
        ... on SanityModule14 {
          _key
          _type
          title
          image {
            asset {
              url
            }
          }
        }
        ... on SanityModule12 {
          _key
          _type
          left_image {
            asset {
              url
            }
          }
          right_image {
            asset {
              url
            }
          }
          title
        }
      }
      thumbnail {
        asset {
          url
        }
      }
    }
  }
`

const ProductTemplate = (props) => {

  const { pageContext, data } = props;
  const { product } = data;

  const window_size = useWindowSize()
  const { width } = window_size;

  useEffect(() => {
    if(window !== undefined) {
      window.scrollTo(0,0)
    }
  }, [data])

  return (
    <>
    <Helmet bodyAttributes={{ class: 'light' }} />
    <Seo title={product.title} description={product.blurb} image={product.thumbnail.asset.url} slug={pageContext.slug} />
      { width > 450 &&
        <Product product={product} />
      }
      { width <= 450 &&
        <ProductMobile product={product} />
      }
    </>
  )
}

export default ProductTemplate

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  min-height: 100vh;
  padding-top: ${props => props.mobile ? '50px' : '0'};
  padding-bottom: ${props => props.mobile ? '50px' : '0'};
  max-height: 100vh;
  @media(max-width: 850px) {
    min-height: 1px;
    padding-top: ${props => props.tablet ? props.tablet : 0};
    padding-bottom: ${props => props.tablet ? props.tablet : 0};
  }
  @media(max-width: 550px) {
    padding-top: ${props => props.mobile ? '0px' : '0'};
    padding-bottom: ${props => props.mobile ? '60px' : '0'};
  }
`;

const Minheight = ({ mobile, tablet, children, border }) => {
  return <Container tablet={tablet} mobile={mobile} border={border}>{children}</Container>;
};

export default Minheight;

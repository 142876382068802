import React from 'react';
import styled from 'styled-components';
import Vertical from '../vertical';
import Minheight from '../minheight';
import ImageRatio from '../image_ratio';

const Flex = styled.section`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-left: 40px;
`;

const Left = styled.div`
  width: calc(50% - 15px);
`;

const Right = styled.div`
  width: calc(50% + 15px);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
`;

const Col = styled.div`
  grid-area: 1 / 2 / 2 / 6;
`

const Module5 = ({ data }) => {
  return (
    <Minheight tablet="100px">
      <Flex>
        <Left><ImageRatio image={data.left_image.asset.url} ratio="710/520" /></Left>
        <Right>
          <Col>
            <Vertical>
              <ImageRatio image={data.right_image.asset.url}  ratio="440/465" />
            </Vertical>
          </Col>
        </Right>
      </Flex>
    </Minheight>
  );
};

export default Module5;

import React from "react";
import styled from 'styled-components'
import Minheight from "../minheight";

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media(max-width: 850px) {
    min-height: 600px;
  }
`

const Module3 = ({ data }) => {
  return <Minheight tablet="100px"><Container image={data.image.asset.url} /></Minheight>
};

export default Module3;

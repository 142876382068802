import React from "react";
import styled from "styled-components";
import Grid from "../grid";
import Minheight from "../minheight";
import Ratio from "../ratio";

const Container = styled.div`
  grid-area: 1 / 4 / 2 / 10;
  @media (max-width: 850px) {
    grid-area: 1 / 3 / 2 / 11;
  }
`;

const Module6 = ({ data }) => {
  return (
    <Minheight tablet="100px">
      <Grid>
        <Container>
          <Ratio
            url={data.image.asset.url}
            padding_percentage="70.14925373134328"
          />
        </Container>
      </Grid>
    </Minheight>
  );
};

export default Module6;

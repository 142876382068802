import React from 'react';
import styled from 'styled-components'
import Minheight from '../minheight';
import ImageRatio from '../image_ratio';

const Flex = styled.div`
  display: flex;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  column-gap: 20px;
`

const Left = styled.div`
  width: 100%;
`

const Right = styled.div`
  width: 100%;
`

const Module8Mobile = ({data}) => {
  return (
    <Minheight mobile>
      <Flex>
          <Left>
            <ImageRatio image={data.left_image.asset.url} ratio="440/580" />
          </Left>
          <Right>
            <ImageRatio image={data.right_image.asset.url}  ratio="440/580" />
          </Right>
      </Flex>
    </Minheight>
  );
};

export default Module8Mobile;

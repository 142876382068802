import React from 'react';
import styled from 'styled-components'
import Grid from '../grid'
import Minheight from '../minheight';
import ImageRatio from "../image_ratio";

const Left = styled.div`
  grid-area: 1 / 2 / 2 / 6;
`

const Right = styled.div`
  grid-area: 1 / 8 / 2 / 12;;
`

const Module9 = ({data}) => {
  return (
    <Minheight tablet="100px">
      <Grid>
          <Left>
            <ImageRatio image={data.left_image.asset.url} ratio="440/310" />
          </Left>
          <Right>
            <ImageRatio image={data.right_image.asset.url}  ratio="440/310" />
          </Right>
      </Grid>
    </Minheight>
  );
};

export default Module9;

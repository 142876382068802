import React from "react";
import styled from 'styled-components'
import Minheight from "../minheight";

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const Module3Mobile = ({data}) => {
  return (
    <div><img src={data.image.asset.url} /></div>
  )
};

export default Module3Mobile;

import React from 'react';
import styled from 'styled-components'
import Minheight from '../minheight';
import ImageRatio from "../image_ratio";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const Left = styled.div`
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;
`

const Right = styled.div`
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
`

const Module9Mobile = ({data}) => {
  return (
    <Minheight mobile>
      <Flex>
          <Left>
            <img src={data.left_image.asset.url} ratio="440/310" />
          </Left>
          <Right>
            <img src={data.right_image.asset.url}  ratio="440/310" />
          </Right>
      </Flex>
    </Minheight>
  );
};

export default Module9Mobile;

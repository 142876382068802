import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Vertical from "../vertical";
import GridTextmodule from "../grid_textmodule";
import Minheight from "../minheight";
import ImageRatio from "../image_ratio";

const Left = styled.div`
  grid-area: 1 / 2 / 2 / 6;
  @media (min-width: 2000px) {
    grid-area: 1 / 4 / 8 / 13;
  }
`;

const Right = styled.div`
  grid-area: 1 / 8 / 2 / 12;
  @media (min-width: 2000px) {
    grid-area: 1 / 16 / 2 / 22;
  }
  @media (max-width: 850px) {
    grid-area: 1 / 7 / 2 / 13;
    display: flex;
    width: 100%;
  }
`;

const Title = styled.p`
  font-family: "Stileman", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  @media (min-width: 2000px) {
    font-size: 17px;
    line-height: 26px;
  }
`;

const Outer = styled.div`
  @media(max-width: 768px) {
    padding-top: ${(props) => (props.index === 0 ? "50px" : "0px")};
  }
`

const Price = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  @media (min-width: 2000px) {
    font-size: 17px;
    line-height: 26px;
  }
  letter-spacing: 0.1em;
  color: #000000;
  margin-top: 2px;
  line-height: 22px;
  letter-spacing: 0.05em;
`;

const Body = styled.div`
  margin-top: 40px;
  font-size: 12px;
  max-width: 390px;
  line-height: 18px;
  @media (min-width: 2000px) {
    font-size: 17px;
    line-height: 26px;
  }
  letter-spacing: 0.05em;
  white-space: pre-line;
  a {
    cursor: pointer;
    pointer-events: auto;
    color: #000;
    border-color: #00000088;
    display: inline;
  }
`;

const Button = styled.a`
  font-family: "Stileman", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 40px;
  display: inline-block;
  padding: 5px 12px;
  border: 0.5px solid #000;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  z-index: 10000;
  pointer-events: auto;
  transition: all 0.25s ease-in-out;
  @media (min-width: 2000px) {
    margin-top: 50px;
    font-size: 13px;
    line-height: 18px;
    padding: 10px 17px;
  }
  &:hover {
    background-color: #788463;
    border-color: #788463;
    color: #000;
  }
`;

const Module2 = ({ data, index }) => {
  const settings = useStaticQuery(graphql`
    query {
      allSanitySettings {
        edges {
          node {
            enquire
          }
        }
      }
    }
  `);

  const enquire_email = settings.allSanitySettings.edges[1].node.enquire;

  const mailto = "mailto:" + enquire_email + "?subject=" + data.title;
  const link_text = data?.link_text ? data.link_text : "Link to PDF download";

  const Bodify = (data, link_text) => {
    if (data.pdf?.asset?.url) {
      return (
        <p>
          {data.body} <a href={data.pdf.asset.url}>{link_text}</a>.
        </p>
      );
    } else {
      return <p>{data.body}</p>;
    }
  };

  return (
    <Outer index={index}>
    <Minheight>
      <GridTextmodule>
        <Left>
          <Vertical>
            <Title>{data.title}</Title>
            <Price>{data.price}</Price>
            <Body>{Bodify(data, link_text)}</Body>
            <Button href={mailto}>Enquire</Button>
          </Vertical>
        </Left>
        <Right>
          <ImageRatio image={data.image.asset.url} ratio="440/580" />
        </Right>
      </GridTextmodule>
    </Minheight>
    </Outer>
  );
};

export default Module2;

import React from "react";
import styled from "styled-components";

// https://ontopmarketing.agency/css-aspect-ratio-calculator/

const Container = styled.div`
  position: relative;
  height: 0;
  padding-bottom: ${(props) => props.p + "%"} !important;
  transform: scale(0.8);
  @media (max-width: 850px) {
    transform: scale(1);
  }
`;

const Inner = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
`;

const Ratio = (props) => {
  const { url, padding_percentage } = props;
  return (
    <Container p={padding_percentage}>
      <Inner bg={url}></Inner>
    </Container>
  );
};

export default Ratio;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  margin-left: 40px;
  margin-right: 40px;
  width: 100%;
  @media (max-width: 850px) {
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const Grid = ({ children, cols }) => {
  const columns = cols ? cols : 12;
  return (<Container columns={columns}>{children}</Container>);
};

export default Grid;

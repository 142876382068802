import React from "react";
import styled from "styled-components";
import Vertical from "../vertical";
import Minheight from "../minheight";
import ImageRatio from "../image_ratio";

const Flex = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 50px;
`;

const Left = styled.div`
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  @media (max-width: 850px) {
    height: 60vh;
  }
`;

const Right = styled.div`
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
`;

const Col = styled.div`
  grid-area: 1 / 2 / 2 / 4;
`;

const Module12Mobile = ({ data }) => {
  return (
    <Minheight>
      <Flex>
        <Left image={data.left_image.asset.url} />
        <Right>
          <Col>
            <Vertical>
              <ImageRatio image={data.right_image.asset.url} ratio="440/580" />
            </Vertical>
          </Col>
        </Right>
      </Flex>
    </Minheight>
  );
};

export default Module12Mobile;

import React from "react";
import { AspectRatio } from "react-aspect-ratio";

const ImageRatio = ({ image, ratio }) => {
  return (
    <AspectRatio
      ratio={ratio}
      style={{
        width: "100%",
        transform: "scale(1)",
        maxWidth: "100%",
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}
    />
  );
};

export default ImageRatio;

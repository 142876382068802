import React from 'react'
import styled from'styled-components'

import Module1Mobile from '../module1_mobile'
import Module2Mobile from '../module2_mobile'
import Module3Mobile from '../module3_mobile'
import Module4Mobile from '../module4_mobile'
import Module5Mobile from '../module5_mobile'
import Module6Mobile from '../module6_mobile'
import Module7Mobile from '../module7_mobile'
import Module8Mobile from '../module8_mobile'
import Module9Mobile from '../module9_mobile'
import Module10Mobile from '../module10_mobile'
import Module11Mobile from '../module11_mobile'
import Module12Mobile from '../module12_mobile'
import Module13Mobile from '../module13_mobile'
import Module14Mobile from '../module14_mobile'

const Section = styled.section`
`

const ProductMobile = (props) => {

  const { product } = props;

  return (
    <div>
    {
      product.modules.map((m, index) => {
        if(m._type === 'module1') {
          return (<Section key={index} className="section"><Module1Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module2') {
          return (<Section key={index} className="section"><Module2Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module3') {
          return (<Section key={index} className="section"><Module3Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module4') {
          return (<Section key={index} className="section"><Module4Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module5') {
          return (<Section key={index} className="section"><Module5Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module6') {
          return (<Section key={index} className="section"><Module6Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module7') {
          return (<Section key={index} className="section"><Module7Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module8') {
          return (<Section key={index} className="section"><Module8Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module9') {
          return (<Section key={index} className="section"><Module9Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module10') {
          return (<Section key={index} className="section"><Module10Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module11') {
          return (<Section key={index} className="section"><Module11Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module12') {
          return (<Section key={index} className="section"><Module12Mobile index={index} data={m} /></Section>)
        } else if(m._type === 'module13') {
          return (<Section key={index} className="section"><Module13Mobile data={m} /></Section>)
        } else if(m._type === 'module14') {
          return (<Section key={index} className="section"><Module14Mobile data={m} /></Section>)
        }
      })}

    </div>
  )
}

export default ProductMobile

import React from "react";
import styled from "styled-components";
import Grid from "../grid";
import Minheight from "../minheight";
import Ratio from "../ratio";

const Container = styled.div`
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
`;

const Module6Mobile = ({ data }) => {
  return (
    <Minheight mobile>
        <Container>
          <Ratio
            url={data.image.asset.url}
            padding_percentage="70.14925373134328"
          />
        </Container>
    </Minheight>
  );
};

export default Module6Mobile;

import React, { useRef, useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect';
import ReactFullpage from '@fullpage/react-fullpage';
import styled from 'styled-components'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

import ArrowDown from '../../images/arrow_down.svg'
import ArrowUp from '../../images/arrow_up.svg'

import Module1 from '../module1'
import Module2 from '../module2'
import Module3 from '../module3'
import Module4 from '../module4'
import Module5 from '../module5'
import Module6 from '../module6'
import Module7 from '../module7'
import Module8 from '../module8'
import Module9 from '../module9'
import Module10 from '../module10'
import Module11 from '../module11'
import Module12 from '../module12'
import Module13 from '../module13'
import Module14 from '../module14'

const Container = styled.div`
  min-height: 100vh;
  position: relative;
  &.tophalf {
    cursor: url(${ArrowUp}) 16 0, pointer;
  }
  &.bottomhalf {
    cursor: url(${ArrowDown}) 16 51, pointer;
  }
  .fullpage-wrapper {
    z-index: 4;
    pointer-events: none;
  }
`

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  @media(max-width: 850px) {
    min-height: 1px;
  }
`

const Product = (props) => {

  const { product } = props;
  const [ halves, setHalves ] = useState('tophalf');
  const fullpageRef = useRef(null);

  const handleMouseMove = event => {
    const screen_halves = event.view?.innerHeight ? (event.view.innerHeight/2) : 1;
    const y = parseInt(event.clientY - event.target.offsetTop);
    if(y > 0 && y < screen_halves) {
      setHalves('tophalf');
    } else if(y >= screen_halves && y < screen_halves * 2) {
      setHalves('bottomhalf');
    } else {
      setHalves('tophalf');
    }
  };

  const goSlide = (direction) => {
    if(direction === 'tophalf') {
      fullpageRef.current.fullpageApi.moveSectionUp();
    } else if(direction === 'bottomhalf') {
      fullpageRef.current.fullpageApi.moveSectionDown();
    }
  }


  const window_size = useWindowSize()
  const { width } = window_size;

  const anchors = product.modules.map((m, index) => {
    return ''+ (index + 1);
  })

  return(

  <Container className={halves} half={halves} onMouseMove={handleMouseMove} onClick={() => goSlide(halves)}>

    { width > 850 && !isMobile &&

    <ReactFullpage
    licenseKey = {'6PGKK-SAK0H-H2756-6AOBJ-WRVJN'}
    anchors={anchors}
    scrollingSpeed = {1500}
    recordHistory={false}
    lazyLoading={false}
    ref={fullpageRef}
    render={({ state, fullpageApi }) => {
    return (
      <ReactFullpage.Wrapper>aaa
      {
        product.modules.map((m, index) => {
          if(m._type === 'module1') {
            return (<Section key={index} className="section"><Module1 data={m} /></Section>)
          } else if(m._type === 'module2') {
            return (<Section key={index} className="section"><Module2 index={index} data={m} /></Section>)
          } else if(m._type === 'module3') {
            return (<Section key={index} className="section"><Module3 data={m} /></Section>)
          } else if(m._type === 'module4') {
            return (<Section key={index} className="section"><Module4 data={m} /></Section>)
          } else if(m._type === 'module5') {
            return (<Section key={index} className="section"><Module5 data={m} /></Section>)
          } else if(m._type === 'module6') {
            return (<Section key={index} className="section"><Module6 data={m} /></Section>)
          } else if(m._type === 'module7') {
            return (<Section key={index} className="section"><Module7 data={m} /></Section>)
          } else if(m._type === 'module8') {
            return (<Section key={index} className="section"><Module8 data={m} /></Section>)
          } else if(m._type === 'module9') {
            return (<Section key={index} className="section"><Module9 data={m} /></Section>)
          } else if(m._type === 'module10') {
            return (<Section key={index} className="section"><Module10 data={m} /></Section>)
          } else if(m._type === 'module11') {
            return (<Section key={index} className="section"><Module11 data={m} /></Section>)
          } else if(m._type === 'module12') {
            return (<Section key={index} className="section"><Module12 data={m} /></Section>)
          } else if(m._type === 'module13') {
            return (<Section key={index} className="section"><Module13 data={m} /></Section>)
          } else if(m._type === 'module14') {
            return (<Section key={index} className="section"><Module14 data={m} /></Section>)
          }
        })}

      </ReactFullpage.Wrapper>

    )}} />

    }

    { (width <= 850 || isMobile) &&
        <>
       {
        product.modules.map((m, index) => {
          if(m._type === 'module1') {
            return (<Section key={index} className="section"><Module1 data={m} /></Section>)
          } else if(m._type === 'module2') {
            return (<Section key={index} className="section"><Module2 data={m} index={index} /></Section>)
          } else if(m._type === 'module3') {
            return (<Section key={index} className="section"><Module3 data={m} /></Section>)
          } else if(m._type === 'module4') {
            return (<Section key={index} className="section"><Module4 data={m} /></Section>)
          } else if(m._type === 'module5') {
            return (<Section key={index} className="section"><Module5 data={m} /></Section>)
          } else if(m._type === 'module6') {
            return (<Section key={index} className="section"><Module6 data={m} /></Section>)
          } else if(m._type === 'module7') {
            return (<Section key={index} className="section"><Module7 data={m} /></Section>)
          } else if(m._type === 'module8') {
            return (<Section key={index} className="section"><Module8 data={m} /></Section>)
          } else if(m._type === 'module9') {
            return (<Section key={index} className="section"><Module9 data={m} /></Section>)
          } else if(m._type === 'module10') {
            return (<Section key={index} className="section"><Module10 data={m} /></Section>)
          } else if(m._type === 'module11') {
            return (<Section key={index} className="section"><Module11 data={m} /></Section>)
          } else if(m._type === 'module12') {
            return (<Section key={index} className="section"><Module12 data={m} /></Section>)
          } else if(m._type === 'module13') {
            return (<Section key={index} className="section"><Module13 data={m} /></Section>)
          } else if(m._type === 'module14') {
            return (<Section key={index} className="section"><Module14 data={m} /></Section>)
          }
        })}


        </>
    }

  </Container>

  )

}

export default Product

import React from "react";
import styled from "styled-components";

const Outer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0;
  width: 100%;
`;

const Inner = styled.div`
  width: 100%;
  @media (max-width: 800px) {
    .react-aspect-ratio-placeholder {

  }
`;

const Vertical = ({ children }) => {
  return (
    <Outer>
      <Inner>{children}</Inner>
    </Outer>
  );
};

export default Vertical;

import React from 'react';
import styled from 'styled-components';
import Vertical from '../vertical';
import Minheight from '../minheight';
import ImageRatio from '../image_ratio';

const Flex = styled.section`
  display: flex;
  width: 100%;
`;

const Left = styled.div`
  width: calc(50% - 10px);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
`;

const Right = styled.div`
  width: calc(50% + 10px);
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  @media (max-width: 850px) {
    height: 40vh;
  }
`;

const Col = styled.div`
  grid-area: 1 / 2 / 2 / 6;
`

const Module13 = ({data}) => {

  return (
    <Minheight tablet="100px">
      <Flex>
        <Left>
          <Col>
            <Vertical>
              <ImageRatio image={data.left_image.asset.url} ratio="440/310" />
            </Vertical>
          </Col>
        </Left>
        <Right image={data.right_image.asset.url} />
      </Flex>
    </Minheight>
  );
};

export default Module13;

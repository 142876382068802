import React from "react";
import styled from "styled-components";
import Grid from "../grid";
import Minheight from "../minheight";
import ImageRatio from "../image_ratio";
import Ratio from "../ratio";

const Container = styled.div`
  grid-area: 1 / 3 / 2 / 11;
  @media (max-width: 850px) {
    grid-area: 1 / 1 / 2 / 12;
  }
`;


const Module14 = ({ data }) => {
  return (
    <Minheight tablet="100px">
      <Grid cols={1}>
        <Container>
          <Ratio url={data.image.asset.url} padding_percentage="100" />
        </Container>
      </Grid>
    </Minheight>
  );
};

export default Module14;
